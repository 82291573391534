import React from "react"
import Swiper from "react-id-swiper"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import "./swiper.scss"
const SimpleSwiper = () => {
  //  keyboard: {
  //   enabled: true,
  //   onlyInViewport: false,
  // },
  const data = useStaticQuery(graphql`
    query {
      sliderOne: file(relativePath: { eq: "slider/1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sliderTwo: file(relativePath: { eq: "slider/2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sliderThree: file(relativePath: { eq: "slider/3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sliderFour: file(relativePath: { eq: "slider/4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1400) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      sliderFive: file(relativePath: { eq: "slider/5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1400) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      sliderSix: file(relativePath: { eq: "slider/6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1400) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      sliderSeven: file(relativePath: { eq: "slider/7.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1400) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      sliderEight: file(relativePath: { eq: "slider/8.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1400) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      sliderNine: file(relativePath: { eq: "slider/9.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1400) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      sliderTen: file(relativePath: { eq: "slider/10.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1400) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      sliderEleven: file(relativePath: { eq: "slider/11.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1400) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      sliderTwelve: file(relativePath: { eq: "slider/12.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1400) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      sliderThirteen: file(relativePath: { eq: "slider/13.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1400) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      sliderFourteen: file(relativePath: { eq: "slider/14.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1400) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      sliderFifteen: file(relativePath: { eq: "slider/15.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sliderSixteen: file(relativePath: { eq: "slider/16.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const params = {
    navigation: {
      nextEl: ".swiper-button-next.swiper-button-black",
      prevEl: ".swiper-button-prev.swiper-button-black",
    },
    keyboard: {
      enabled: true,
      onlyInViewport: false,
    },
    a11y: {
      prevSlideMessage: "Previous slide",
      nextSlideMessage: "Next slide",
    },
  }
  return (
    <Swiper {...params}>
      <div>
        <Img fluid={data.sliderOne.childImageSharp.fluid} alt="Slider 1" />
      </div>
      <div>
        <Img fluid={data.sliderTwo.childImageSharp.fluid} alt="Slider 2" />
      </div>
      <div>
        <Img fluid={data.sliderThree.childImageSharp.fluid} alt="Slider 3" />
      </div>
      <div>
        <Img fluid={data.sliderFour.childImageSharp.fluid} alt="Slider 4" />
      </div>

      <div>
        <Img fluid={data.sliderFive.childImageSharp.fluid} alt="Slider 5" />
      </div>
      <div>
        <Img fluid={data.sliderSix.childImageSharp.fluid} alt="Slider 6" />
      </div>

      <div>
        <Img fluid={data.sliderSeven.childImageSharp.fluid} alt="Slider 7" />
      </div>

      <div>
        <Img fluid={data.sliderEight.childImageSharp.fluid} alt="Slider 8" />
      </div>
      <div>
        <Img fluid={data.sliderNine.childImageSharp.fluid} alt="Slider 9" />
      </div>
      <div>
        <Img fluid={data.sliderTen.childImageSharp.fluid} alt="Slider 10" />
      </div>

      <div>
        <Img fluid={data.sliderEleven.childImageSharp.fluid} alt="Slider 11" />
      </div>
      <div>
        <Img fluid={data.sliderTwelve.childImageSharp.fluid} alt="Slider 12" />
      </div>

      <div>
        <Img
          fluid={data.sliderThirteen.childImageSharp.fluid}
          alt="Slider 13"
        />
      </div>

      <div>
        <Img
          fluid={data.sliderFourteen.childImageSharp.fluid}
          alt="Slider 14"
        />
      </div>

      <div>
        <Img fluid={data.sliderFifteen.childImageSharp.fluid} alt="Slider 15" />
      </div>
      <div>
        <Img fluid={data.sliderSixteen.childImageSharp.fluid} alt="Slider 3" />
      </div>
    </Swiper>
  )
}
export default SimpleSwiper
