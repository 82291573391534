import React from "react"
import Layout from "../components/layout"
import Intro from "../components/intro"
import Story from "../components/story"
import Guitars from "../components/guitars"
import TimelineContainer from "../components/timeline-container"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Intro />
    <Story />
    <Guitars />
    <TimelineContainer />
  </Layout>
)

export default IndexPage
