import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import HeroImage from "../components/hero-image"
import Swiper from "../components/swiper"
// import SimpleSwiper from "./swiper"

const Intro = () => {
  const data = useStaticQuery(graphql`
    query {
      thirtyFiveLogo: file(relativePath: { eq: "35-logo-triangle.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      <section className="anniversary-section mt-30 md:mt-0">
        <HeroImage />
        <div className="anniversary-section-inner max-w-4xl mx-auto">
          <div className="flex max-w-xs md:max-w-md mx-auto left-0 top-0 right-0 justify-between">
            <div className="pt-10 left-0 text-2xl font-header font-bold text-gray-500">
              <p>1985</p>
            </div>
            <div className="-mt-12 md:-mt-20">
              <Img
                fluid={data.thirtyFiveLogo.childImageSharp.fluid}
                alt="35th Anniversary Logo"
                className="w-32 md:w-48"
              />
            </div>
            <div className="pt-10 left-0 text-2xl font-header font-bold text-gray-500">
              <p>2020</p>
            </div>
          </div>

          <p className="pb-3 pt-12 pl-4 pr-4 lg:pl-0 lg:pr-0 font-body">
            "35 years...When I look back to 1985, I remember a small group of
            PRS craftsmen making guitars, teaching each other, and learning as
            we went. We felt every success and every stumble. We knew we were on
            a journey and were discovering new things every day. Whether it was
            a how to make the machinery we needed, how to find and select the
            best woods, how to dry the wood, how to control the factory’s
            humidity, or what stains to use – it was very much the beginning of
            something that you could feel in the shop.
          </p>

          <p className="pb-3 pl-4 pr-4 lg:pl-0 lg:pr-0 font-body">
            And while so much has changed through the last thirty-five years,
            that process of listening, learning, adapting, and continually
            pushing ourselves and our craft forward is still how we operate. We
            are always trying to retain what is good and improve what needs to
            be better for our craft and our customers.
          </p>

          <p className="pb-3 pl-4 pr-4 lg:pl-0 lg:pr-0 font-body">
            It’s special what is going here, and I am very appreciative. Our
            hope is that all this translates to the guitars, amps, and
            accessories you use to make your art and in some cases your living.
            We know we wouldn’t be here without all of you. Your trust and
            support are fundamental to our ability to live this process. Thank
            you.”{" "}
          </p>

          <p className="pb-12 font-bold text-center font-header text-3xl">
            - Paul Reed Smith
          </p>
          <div className="max-w-4xl mx-auto">
            <Swiper />
          </div>
          <div className="w-full overflow-hidden text-center">
            <hr className="w-full max-w-xs md:max-w-md block mx-auto my-20" />
          </div>
        </div>
      </section>
    </>
  )
}

export default Intro
