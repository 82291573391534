import React from "react"
import { Link } from "gatsby"

const TimelineContainer = () => (
  <section className="anniversary-section mt-20 md:mt-0">
    <div className="anniversary-section-inner max-w-4xl mx-auto pb-32">
      <h2 className="pb-3 font-header bold text-black text-center uppercase">
        35 Years In Moments: A Timeline
      </h2>

      <p className="pl-4 pr-4 lg:pl-0 lg:pr-0 font-body">
        PRS Guitars officially started in 1985, but the journey began much
        before that. Take a look back at 35 moments that have been pivotal to
        the success of PRS Guitars.
      </p>
      <Link
        to="/timeline"
        className="block mt-12 mr-auto ml-auto w-64 bg-red hover:bg-lightred text-white font-header uppercase text-center font-bold py-2 px-4 rounded"
      >
        View Our 35 Year Timeline
      </Link>
    </div>
  </section>
)

export default TimelineContainer
