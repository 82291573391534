import React from "react"

const Story = () => (
  <section id="story" className="anniversary-section mt-20 md:mt-0">
    <div className="anniversary-section-inner max-w-4xl mx-auto">
      <h2 className="pt-20 font-header bold text-black text-center uppercase">
        Celebrating 35 Years
      </h2>
      <h3 className="font-header bold text-black text-center uppercase pt-3">
        1985-2020
      </h3>

      <p className="pl-4 pr-4 pt-3 lg:pl-0 lg:pr-0 font-body">
        2020 marks the 35th Anniversary of PRS Guitars, and we are celebrating
        with exciting introductions to the{" "}
        <a
          href="https://www.prsguitars.com/products/se/"
          className="underline hover:text-gray-700"
        >
          SE Series
        </a>{" "}
        , long-awaited{" "}
        <a
          href="https://www.prsguitars.com/index.php/news/story/prs_guitars_announces_new_mark_holcomb_dustie_waring_signature_models"
          className="underline hover:text-gray-700"
        >
          {" "}
          artist model{" "}
        </a>{" "}
        announcements, updates to the{" "}
        <a
          href="https://prsguitars.com/products/core#mccarty"
          className="underline hover:text-gray-700"
        >
          McCarty family
        </a>{" "}
        of instruments several updates to our Maryland-made guitar lines,
        including TCI-tuned pickups and a new nitro finish, and three{" "}
        <a href="/#guitars" className="underline hover:text-gray-700">
          35th Anniversary Custom 24
        </a>{" "}
        models.
      </p>

      <p className="pl-4 pr-4 lg:pl-0 lg:pr-0 font-body">
        We have more announcements coming throughout the year, so check back
        often to see what’s new! And don’t forget to grab some{" "}
        <a
          href="https://prsaccessories.com"
          className="underline hover:text-gray-700"
        >
          swag
        </a>{" "}
        and come celebrate with us at{" "}
        <a
          href="https://www.prsguitars.com/index.php/events/event/experience_prs_2020"
          className="underline hover:text-gray-700"
        >
          Experience PRS 2020
        </a>
        .
      </p>

      <div className="w-full overflow-hidden text-center">
        <hr className="w-full max-w-xs md:max-w-md block mx-auto my-20" />
      </div>
    </div>
  </section>
)

export default Story
