import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
// import guitarPlaceholder from "../images/guitar-placeholder.png"

const Guitars = () => {
  const data = useStaticQuery(graphql`
    query {
      dragonGuitarImage: file(relativePath: { eq: "dragon-guitar.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      coreGuitarImage: file(relativePath: { eq: "core-guitar.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      s2GuitarImage: file(relativePath: { eq: "s2-guitar.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      seGuitarImage: file(relativePath: { eq: "se-guitar.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <section className="anniversary-section mt-20 md:mt-0" id="guitars">
      <div className="anniversary-section-inner max-w-4xl mx-auto">
        <h2 className="pb-6 font-header bold text-black text-center uppercase">
          35th Anniversary Models
        </h2>
        <div className="flex max-w-3xl m-auto text-center">
          <div className="flex-grow w-3/12">
            <a href="https://www.prsguitars.com/index.php/electrics/model/private_stock_35th_anniversary_dragon">
              <h3 className="font-header bold uppercase">Private Stock</h3>
              <h4 className="font-header font-normal pb-6">
                35th Anniversary Dragon
              </h4>
              <Img
                fluid={data.dragonGuitarImage.childImageSharp.fluid}
                alt="Core"
                className="w-10/12 mx-auto"
              />
              <ul className="font-body mt-6 text-sm md:text-base">
                <li>135 Pieces Worldwide</li>
                <li>Elaborate Dragon Inlay</li>
                <li>Frostbite Dragon’s Breath Finish</li>
              </ul>
            </a>
          </div>
          <div className="flex-grow w-3/12">
            <a href="https://www.prsguitars.com/index.php/electrics/model/35th_anniversary_s2_custom_24">
              <h3 className="font-header bold uppercase">S2</h3>
              <h4 className="font-header font-normal pb-6">
                35th Anniversary Custom 24
              </h4>
              <Img
                fluid={data.s2GuitarImage.childImageSharp.fluid}
                alt="S2"
                className="w-10/12 mx-auto"
              />
              <ul className="font-body mt-6 text-sm md:text-base">
                <li>85/15 “S” Pickups</li>
                <li>Patented Tremolo, Molded</li>
                <li>Low Mass Locking Tuners</li>
              </ul>
            </a>
          </div>
          <div className="flex-grow w-3/12">
            <a href="https://www.prsguitars.com/index.php/electrics/model/35th_anniversary_custom_24_2020">
              <h3 className="font-header bold uppercase">Core</h3>
              <h4 className="font-header font-normal pb-6">
                35th Anniversary Custom 24
              </h4>
              <Img
                fluid={data.coreGuitarImage.childImageSharp.fluid}
                alt="Core"
                className="w-10/12 mx-auto"
              />
              <ul className="font-body mt-6 text-sm md:text-base">
                <li>85/15 Pickups</li>
                <li>Patented Tremolo, Machined</li>
                <li>Phase III Tuners</li>
              </ul>
            </a>
          </div>
          <div className="flex-grow w-3/12">
            <a href="https://prsguitars.com/electrics/model/35th_anniversary_se_custom_24_2020">
              <h3 className="font-header bold uppercase">SE</h3>
              <h4 className="font-header font-normal pb-6">
                35th Anniversary Custom 24
              </h4>
              <Img
                fluid={data.seGuitarImage.childImageSharp.fluid}
                alt="SE"
                className="w-10/12 mx-auto"
              />
              <ul className="font-body mt-6 text-sm md:text-base">
                <li>TCI “S” Pickups</li>
                <li>Patented Tremolo, Molded</li>
                <li>LTD Production (3500 pcs)</li>
              </ul>
            </a>
          </div>
        </div>
        <div className="w-full overflow-hidden text-center">
          <hr className="w-full max-w-xs md:max-w-md block mx-auto my-20" />
        </div>
      </div>
    </section>
  )
}

export default Guitars
